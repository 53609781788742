import React from 'react';
import { Twitter, Instagram, MapPin, Users, Link as LinkIcon } from 'lucide-react';

const DiscordIcon: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
    <path d="M9 12m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0"></path>
    <path d="M15 12m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0"></path>
    <path d="M7.5 7.5c3.5-1 5.5-1 9 0"></path>
    <path d="M7 16.5c3.5 1 6.5 1 10 0"></path>
    <path d="M15.5 17c0 1 1.5 3 2 3c1.5 0 2.833 -1.667 3.5 -3c.667 -1.667 .5 -5.833 -1.5 -11.5c-1.457 -1.015 -3 -1.34 -4.5 -1.5l-1 2.5"></path>
    <path d="M8.5 17c0 1 -1.356 3 -1.832 3c-1.429 0 -2.698 -1.667 -3.333 -3c-.635 -1.667 -.476 -5.833 1.428 -11.5c1.388 -1.015 2.782 -1.34 4.237 -1.5l1 2.5"></path>
  </svg>
);

const ButtonStyle = "transition-all duration-300 ease-in-out transform hover:-translate-y-1 hover:shadow-lg rounded-full";

const NearEastLandingPage: React.FC = () => {
  return (
    <div className="min-h-screen bg-[#FFFFFF] text-[#3F3F3F] flex flex-col">
      <main className="flex-grow">
        <section className="bg-[#37556D] text-[#FFFFFF] py-12">
          <div className="container mx-auto text-center px-4">
            <h1 className="text-3xl md:text-4xl font-bold mb-4 leading-tight text-white">Welcome to NearEast.io</h1>
            <p className="text-lg mb-6 max-w-2xl mx-auto">An inclusive digital space for students passionate about exploring the history, culture, and current affairs of the Near East</p>
            <a
              href="#join"
              className={`inline-block bg-[#B09BF4] text-white py-2 px-6 text-base font-semibold hover:bg-[#9A7EF2] ${ButtonStyle}`}
            >
              Join Our Community
            </a>
          </div>
        </section>

        <section className="py-12 bg-[#F6F6F6]">
          <div className="container mx-auto px-4">
            <h2 className="text-2xl font-bold mb-8 text-center text-[#37556D]">Upcoming Events</h2>
            <div className="grid md:grid-cols-2 gap-6">
              {[1, 2].map((eventNum) => (
                <div key={eventNum} className="bg-white rounded-lg p-4 shadow-md transition-all duration-300 hover:shadow-lg">
                  <h3 className="text-xl font-bold mb-2 text-[#37556D]">Event Title {eventNum}</h3>
                  <div className="flex items-center mb-1 text-[#3F3F3F] text-sm">
                    <span className="font-semibold mr-2">Date:</span>
                    <span>TBA</span>
                  </div>
                  <div className="flex items-center mb-1 text-[#3F3F3F] text-sm">
                    <MapPin className="w-4 h-4 mr-2" />
                    <span>Location: TBA</span>
                  </div>
                  <div className="flex items-center mb-2 text-[#3F3F3F] text-sm">
                    <Users className="w-4 h-4 mr-2" />
                    <span>Guests: TBA</span>
                  </div>
                  <p className="mb-4 text-sm">Event description will be added here. Stay tuned for more details!</p>
                  <button className={`bg-[#A0CFDB] text-[#37556D] py-1 px-4 text-sm flex items-center hover:bg-[#8BBFD1] ${ButtonStyle}`}>
                    <LinkIcon className="w-3 h-3 mr-1" />
                    Join Online
                  </button>
                </div>
              ))}
            </div>
          </div>
        </section>

        <section className="py-12 bg-[#FFFFFF]">
          <div className="container mx-auto px-4">
            <h2 className="text-2xl font-bold mb-6 text-center text-[#37556D]">Who we are</h2>
            <div className="bg-[#F6F6F6] rounded-lg p-6 shadow-md max-w-3xl mx-auto">
              <p className="space-y-3 text-[#3F3F3F] text-sm leading-relaxed">
                <span className="block">
                  NearEast is a student-led community dedicated to those who seek an in-depth understanding of Middle Eastern politics, culture, and economics. Our mission is to create a dynamic space for students to exchange ideas, expand their interests, and actively contribute to a nuanced view of the region.
                </span>
                <span className="block">
                  We believe true learning means engaging critically, challenging assumptions, and collaborating to uncover fresh perspectives. At NearEast, every student has the opportunity to take the lead—whether by organizing discussions, hosting events, or sharing their research.
                </span>
                <span className="block">
                  Together, we're building a community rooted in curiosity, involvement, and a passion for understanding the Middle East.
                </span>
              </p>
            </div>
          </div>
        </section>

        <section className="py-12 bg-[#F6F6F6]" id="join">
          <div className="container mx-auto px-4">
            <div className="grid md:grid-cols-2 gap-6">
              <div className="bg-white rounded-lg p-6 shadow-md transition-all duration-300 hover:shadow-lg flex flex-col">
                <h2 className="text-xl font-bold mb-4 text-[#37556D]">Join the Conversation: NearEast Forum</h2>
                <p className="mb-4 text-[#3F3F3F] text-sm leading-relaxed flex-grow">
                  <span className="block mb-2">
                    Our forum is open to everyone, offering a lively platform where students can exchange ideas on Middle Eastern topics, share unique content, and discover new articles.
                  </span>
                  <span className="block">
                    It's a place for exploring various perspectives, diving into discussions, and connecting with a community passionate about understanding the region.
                  </span>
                </p>
                <a
                  href="https://forum.neareast.io"
                  className={`inline-block bg-[#B09BF4] text-white py-2 px-4 text-sm hover:bg-[#9A7EF2] text-center ${ButtonStyle}`}
                >
                  Explore our Forum →
                </a>
              </div>
              <div className="bg-white rounded-lg p-6 shadow-md transition-all duration-300 hover:shadow-lg flex flex-col">
                <h2 className="text-xl font-bold mb-4 text-[#37556D]">In-Depth Perspectives by Students</h2>
                <p className="mb-4 text-[#3F3F3F] text-sm leading-relaxed flex-grow">
                  <span className="block mb-2">
                    The NearEast blog is dedicated to exploring diverse topics within the Middle East and North Africa (MENA) region, offering articles and insights on its politics, culture, and economics.
                  </span>
                  <span className="block">
                    Launched by Saman Sayahpour, the blog provides an in-depth look into key issues and developments in MENA, encouraging contributions and discussions from students interested in a comprehensive understanding of the region.
                  </span>
                </p>
                <a
                  href="https://blog.neareast.io"
                  className={`inline-block bg-[#A0CFDB] text-[#37556D] py-2 px-4 text-sm hover:bg-[#8BBFD1] text-center ${ButtonStyle}`}
                >
                  Visit our Blog →
                </a>
              </div>
            </div>
          </div>
        </section>

        <section className="py-12 bg-[#F6F6F6]">
          <div className="container mx-auto px-4">
            <div className="bg-white rounded-lg p-6 shadow-lg max-w-2xl mx-auto">
              <h2 className="text-2xl font-bold mb-4 text-center text-[#37556D]">Stay Updated</h2>
              <p className="text-center mb-6 text-sm text-[#3F3F3F]">Join our newsletter to receive the latest updates on events, articles, and community news.</p>
              <form className="flex flex-col md:flex-row space-y-3 md:space-y-0 md:space-x-3">
                <input
                  type="email"
                  placeholder="Enter your email"
                  className="flex-grow px-4 py-2 rounded-full text-sm border border-[#A0CFDB] focus:outline-none focus:ring-2 focus:ring-[#B09BF4] focus:border-transparent"
                />
                <button
                  type="submit"
                  className={`bg-[#B09BF4] text-white px-6 py-2 text-sm font-semibold hover:bg-[#9A7EF2] focus:outline-none focus:ring-2 focus:ring-[#B09BF4] focus:ring-offset-2 ${ButtonStyle}`}
                >
                  Subscribe
                </button>
              </form>
            </div>
          </div>
        </section>
      </main>

      <footer className="bg-[#37556D] text-white py-8">
        <div className="container mx-auto px-4 flex flex-col md:flex-row justify-between items-center">
          <p className="mb-4 md:mb-0 text-sm">&copy; 2024 NearEast n. e. V. All rights reserved.</p>
          <div className="flex space-x-4">
            <a href="https://x.com" target="_blank" rel="noopener noreferrer" className="hover:text-[#A0CFDB] transition-colors duration-300">
              <Twitter className="w-5 h-5" />
            </a>
            <a href="https://discord.com" target="_blank" rel="noopener noreferrer" className="hover:text-[#A0CFDB] transition-colors duration-300">
              <DiscordIcon className="w-5 h-5" />
            </a>
            <a href="https://instagram.com" target="_blank" rel="noopener noreferrer" className="hover:text-[#A0CFDB] transition-colors duration-300">
              <Instagram className="w-5 h-5" />
            </a>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default NearEastLandingPage;