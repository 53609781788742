import React from 'react';
import ReactDOM from 'react-dom/client';
import NearEastLandingPage from './NearEastLandingPage.tsx';
import './index.css'; // You may need to create this file for global styles

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <NearEastLandingPage />
  </React.StrictMode>
);